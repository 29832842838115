.ReactModal__Content {
    @apply opacity-0;

    transform: translateX(-100px);
    transition: all 300ms ease-in-out;
}

.ReactModal__Content--after-open {
    @apply opacity-100;

    transform: translateX(0px);
}

.ReactModal__Content--before-close {
    @apply opacity-0;

    transform: translateX(-100px);
}
