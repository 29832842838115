@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    h1,
    h2,
    h3,
    p,
    dd,
    dt {
        @apply text-neutrals-900;
    }

    .input-arrows::-webkit-outer-spin-button,
    .input-arrows::-webkit-inner-spin-button {
        @apply opacity-100;
    }
}

@layer components {
    .display {
        @apply text-64 sm:text-96;
    }
    .heading-1 {
        @apply text-32 sm:text-64;
        line-height: 133%;
    }
    .heading-2 {
        @apply xs:text-24 md:text-36 text-45;
    }
    .heading-3 {
        @apply text-30 sm:text-36;
    }
    .heading-4 {
        @apply text-24 sm:text-30;
    }
    .heading-5 {
        @apply text-20 sm:text-24;
    }
    .heading-6 {
        @apply text-18 sm:text-20;
    }
    .heading-1, .heading-2, .heading-3, .heading-4, .heading-5, .heading-6 {
        /* capitalize here for legacy styles, should be removed */
        @apply font-roslindale capitalize font-bold safari-mobile:font-normal;

        &.name {
            /* ug, can't just capitalize everything - not every word in a name should be capitalized */
            text-transform: inherit;
        }
    }
    .heading-desc {
        @apply font-favoritemono;
        font-size: 1.125rem;
        line-height: 177.77%;
        text-transform: uppercase;
        font-weight: 500;
    }
}
